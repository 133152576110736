<script>

import axios from "axios"
import appConfig from "@/app.config.json";

import {mapState} from "vuex";
import {loggedInfoMethods, loggedInfoObject} from "@/state/helpers";

export default {
  page: {
    title: "로그인",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      id: "",
      pw: "",
      saveId: false,
      submitted: false,
      isAuthError: true,
      authError: null,
    }
  },
  mounted() {
    //console.log('--------', this.$cookies.get('id'));
    if (this.$cookies.get('id') !== null) {
      this.saveId = true
      //console.log('saveId', this.saveId);
      this.id = this.$cookies.get('id');
    }
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  methods: {
    ...loggedInfoMethods,
    tryToLogin() {
      this.submitted = true

      // const accountInfo = {id: this.id, pw: this.pw, device_srl: "9876543210"};

      if (this.saveId) {
        this.$cookies.set('id', this.id)
      } else {
        this.$cookies.remove('id')
      }

      // // eslint-disable-next-line
      // const key = this.$CryptoJS.enc.Utf8.parse(process.env.VUE_APP_KEY);
      // // eslint-disable-next-line
      // const iv = this.$CryptoJS.enc.Hex.parse(process.env.VUE_APP_IV);
      //
      // let encrypt = this.$CryptoJS.AES.encrypt(JSON.stringify(accountInfo), key, {iv: iv});
      // let strEncrypt = encrypt.toString().replaceAll('/', '*');

      if (this.id !== "" && this.pw !== "") {
        axios.post(
            `/api/user/mart/login`,
            {
              'userid': this.id,
              'password': this.pw,
            })
            .then(
                (result) => {
                  console.log(result.data);

                  if (result.data['status'] === 'fail') {
                    this.$data.isAuthError = true;
                    if (result.data['msg'] === 'not user') {
                      this.$data.authError = '아이디를 확인해주세요.';
                    } else {
                      this.$data.authError = '비밀번호 정보를 확인해주세요.';
                    }
                  } else if (result.data['status'] === 'not user') {
                    this.$data.isAuthError = true;
                    this.$data.authError = '아이디를 확인해주세요.';
                  } else {
                    //this.$store.commit("setLoggedInfo", result.data);
                    this.setLoggedInfo(result.data);
                    //console.log(this.logged_info);
                    this.setAuth(true)

                    if (this.logged_info.business_type !== 'clan') {
                      // console.log("FALSE   FALSE   FALSE   FALSE   FALSE   FALSE   FALSE   FALSE   FALSE")
                      this.$router.push(
                          this.$route.query.redirectFrom || {
                            name: "home",
                          }
                      ).catch(error => {
                        console.warn(error.message)
                      })
                    } else {
                      this.$router.push('macltalk').catch(err => {
                        console.warn(err.message)
                      })
                    }

                    // console.log(this.$store.state.logged_info);
                  }
                })
            .catch((error) => {
              console.log(`Login Get Axios ${error}`);
            })


      }
    },
  },
}
</script>

<template>
  <div class="authentication-bg min-vh-100">
    <div class="bg-overlay bg-white"></div>
    <div class="container">
      <div class="d-flex flex-column min-vh-100 px-3 pt-4">
        <div class="row justify-content-center my-auto">
          <div class="col-md-8 col-lg-6 col-xl-4">
            <div class="text-center py-5">
              <div class="mb-2 mb-md-3">
                <router-link to="/" class="d-block auth-logo">
                  <!--                  src="@/assets/mcimages/logo@3x.png"-->
                  <img
                      src="@/assets/images/dagImages/dagLogoNoBackground.png"
                      alt=""
                      height="160"
                      class="auth-logo"
                  />
                </router-link>
              </div>
              <div class="mb-4">
                <!--                <h5>Welcome Back !</h5>-->
                <p>점주를 위한 서비스입니다.</p>
              </div>
              <!--              <b-alert
                                v-model="this.$data.isAuthError"
                                variant="danger"
                                class="mt-3"
                                dismissible
                            > {{ this.$data.authError }} </b-alert>-->
              <div v-if="this.$data.authError !== null"
                   class="alert alert-danger">
                {{ this.$data.authError }}
              </div>
              <form @submit.prevent="tryToLogin">
                <div class="form-floating form-floating-custom mb-3">
                  <input
                      type="text"
                      v-model="id"
                      class="form-control"
                      id="input-id"
                      placeholder="Enter User Name"
                      :class="{ 'is-invalid': submitted && this.$data.id === '' }"
                  />
                  <label for="input-id">아이디</label>
                  <div class="form-floating-icon">
                    <i class="uil uil-envelope-alt"></i>
                  </div>
                  <div
                      v-if="this.$data.id === ''"
                      class="invalid-feedback"
                  >
                    아이디를 입력해주세요.
                  </div>
                </div>
                <div class="form-floating form-floating-custom mb-3">
                  <input
                      type="password"
                      class="form-control"
                      v-model="pw"
                      id="input-password"
                      placeholder="Enter Password"
                      :class="{ 'is-invalid': submitted && this.$data.pw ==='' }"
                  />
                  <label for="input-password">비밀번호</label>
                  <div class="form-floating-icon">
                    <i class="uil uil-padlock"></i>
                  </div>
                  <div
                      v-if="submitted && this.$data.pw ===''"
                      class="invalid-feedback"
                  >
                    비밀번호를 입력해주세요.
                  </div>
                </div>

                <div class="form-check form-check-info font-size-16">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      id="remember-check"
                      v-model="saveId"
                  />
                  <label
                      class="form-check-label font-size-14"
                      for="remember-check"
                  >
                    아이디 저장
                  </label>
                </div>

                <div class="mt-3 mb-3">
                  <button class="btn btn-info w-100" type="submit">
                    로그인
                  </button>
                </div>

                <router-link to="/signup">
                  아직 회원이 아니신가요? 회원가입은 여기로
                </router-link>

                <!--                <div class="mt-4">
                                  <router-link
                                      to="/auth/resetpassword-basic"
                                      class="text-muted text-decoration-underline"
                                  >비밀번호를 잊으셨나요?
                                  </router-link
                                  >
                                </div>-->
              </form>
              <!-- end form -->

              <!--              <div class="mt-5 text-center text-muted">
                              <p>
                                계정이 없으신가요?
                                <router-link
                                    to="/register"
                                    class="fw-medium text-decoration-underline"
                                >
                                  회원가입
                                </router-link>
                              </p>
                            </div>-->
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->

        <div v-if="false" class="row">
          <div class="col-xl-12" style="margin-bottom: 30px">
            <div class="text-center text-muted p-4">
              <strong>상호</strong>
              크리티컬 &nbsp;&nbsp;
              <strong>대표</strong>
              손선호 &nbsp;&nbsp;
              <strong>사업자 등록번호</strong>
              141-76-00382<br/>
              <strong>주소</strong>
              대구광역시 서부 북비산로 260, 703호&nbsp;&nbsp;
              <strong>고객센터</strong>
              070-4001-0146 (평일 11:00 ~ 18:00)&nbsp;&nbsp;
              <strong>email</strong>
              master@critical.co.kr
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
    <!-- end container -->
  </div>
  <!-- end authentication section -->
</template>
